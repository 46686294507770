span#Breadcrump {
    display: inline-flex;
    color: #949494;
    // padding-right: 16px;

    p {
        // font-size: 1.3rem;
        cursor: pointer;
        margin: 0;

        &::after {
            content: '/';
            margin: 0 10px;
        }

        &:last-of-type::after {
            content: '';
        }
    }
}