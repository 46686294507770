button#Button {
    background-color: inherit;
    border: 1px solid;
    cursor: pointer;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    padding: 3px 10px;
    text-transform: capitalize;

    &:not(.css-btn-esc1, .css-btn-esc2, .css-btn-esc3, .css-btn-esc4) {
        background-color: #eee;
        border-radius: 0;
    }

    &:focus {
        outline: none;
    }

    &:active {
        transform: scale(.95);
    }

    &.reverse {
        flex-direction: row-reverse;
    }

    img {
        width: 20px;
    }

    &.css-btn-esc1 {
        border: 1px solid #B98772;
        color: #3E3E3E;
    }

    &.css-btn-esc2 {
        background: #0c124a;
        color: #fff;
        padding: 6px 50px;
        border-radius: 6px;

        &.inactive {
            background-color: gray;
        }

        &.sm {
            padding: 6px 12px;
            width: 40px;
        }
    }

    &.css-btn-esc3 {
        color: #0c124a;
        padding: 6px 30px;
        min-width: 130px;

        &.sm {
            padding: 6px 25px;
            min-width: 40px;
        }

        img {
            width: 15px;
        }

        &:disabled {
            border-color: rgba(10, 15, 49, 0.25);
            color: rgba(0, 0, 0, 0.5);
            background-color: rgba(0, 0, 0, 0.05);
        }
    }

    &.css-btn-esc4 {
        font-weight: 600;
        color: #0A0F31;
        padding: 1px 8px;
        font-size: 14px;
        border: 1px solid #0A0F31;
    }

    &.css-btn-esc5 {
        font-weight: 600;
        color: #474747d4;
        padding: 8px 35px;
        font-size: 16px;
        border: 1px solid #F4ECE8;
        background: #F4ECE8;
        border: none;
        border-radius: 5px;
    }

    &.css-btn-esc6 {
        background-color: #f8ece8;
        border-radius: 8px;
        border: none;

        img {
            height: 20px;
        }
    }

    &.css-btn-esc7 {
        padding: 12px 20px;
        border: 1px solid #d9d9d99e;
        border-radius: 8px;
        background-color: inherit;
    }
}