@font-face {
  font-family: 'Dubai-medium';
  src: url('../public/assets/fonts/DubaiW23-Medium.woff');
}

@font-face {
  font-family: 'Dubai-regular';
  src: url('../public/assets/fonts/DubaiW23-Regular.woff');
}

@font-face {
  font-family: 'Dubai-light';
  src: url('../public/assets/fonts/DubaiW23-Light.woff');
}

@font-face {
  font-family: 'Dubai-bold';
  src: url('../public/assets/fonts/DubaiW23-Bold.woff');
}

body:has(.App) {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
  font-family: 'Dubai-regular';
  color: var(--color14);
  overflow-y: scroll;
  line-height: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --color1: #ffffff;
  --color2: #B98772;
  --color2-lighten: #dbc6bd;
  --color3: #E8E8E8;
  --color4: #707070;
  --color5: #0A0F31;
  --color6: #F4ECE8;
  --color7: #A8A8A8;
  --color8: #949494;
  --color9: #1A1A1A;
  --color10: #ECECEC;
  --color11: #f3f3f3;
  --color12: #9e9e9e;
  --color13: #161616;
  --color13-lighten: #16161658;
  --color14: #474747;
  --color15: #F6CDB9;
  --color16: #3A3838;
  --color17: #3E3E3E;

  --navBarWidth: 250px;
}

.App {
  direction: rtl;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }

  ul {
    list-style: none;
  }
}

.direction-rtl {
  direction: rtl;
}

.delete__modal {
  padding: 40px;
}

.delete__modal h2 {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.delete__modal .modal-footer {
  border: none;
  justify-content: center;
  padding-top: 0;
}

.form-check-input:checked {
  background-color: var(--color2) !important;
  border-color: var(--color2) !important;
}

input[type=checkbox] {
  accent-color: var(--color2);
}

button.btn.btn-primary {
  background: #0c124a;
  border: 1px solid #0c124a;
}
