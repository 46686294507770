#CertificateRequests,
#AddRequest_admin,
#HumanResource_admin,
#onboarding__process,
#RequestDetails,
#RequestDetails_admin,
#CertificateRequests_admin_report,
#AddRequest {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

#AddRequest {
  gap: 0px;
}

#AddRequest #RequestDetailsForm .title,
#AddRequest_admin #SelectEmployee .title,
#RequestDetails #RequestDetailsData .title,
#RequestDetails_admin #PersonalInformation .title {
  border-radius: 20px 20px 0 0;
}

// LOGIN

section.hr__login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-self: center;
  background-image: url("../public/assets/img/login.png");
  background-size: cover;

  h3 {
    color: #707070;
    font-size: 20px;
    margin: 0;
    text-align: right;
    font-weight: 600;
  }

  .vh-100 {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-self: center
  }

  .login__form {
    padding: 60px 80px;
    background: #fff;
    box-shadow: 0px 6px 49px #00000029;
    border-radius: 25px;
    min-width: 400px;
  }

  .form-control {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    padding: 12px 20px;
    direction: rtl;

    &:focus {
      border: 1px solid #E8E8E8;
      box-shadow: none;
    }
  }

  button.btn.btn-primary {
    background: #B98772 0% 0% no-repeat padding-box !important;
    border-radius: 4px;
    opacity: 1;
    border: #b98772 !important;
    font-size: 14px;
    padding: 12px 20px;
    width: 100%;
    margin-top: 20px;
  }

  .mb-3 {
    width: 400px;
    margin: 0 auto;
  }

  @media only screen and (max-width: 1200px) {
    .mb-3 {
      width: 100%;
    }
  }

  @media only screen and (max-width: 500px) {

    .login__form {
      min-width: 90%;
    }

    .mb-3 {
      width: 100%;
    }
  }

  img.logo {
    position: absolute;
    right: 5%;
    top: 10%;
    height: 62px;
  }
}

.modal-padding {
  padding: 40px;

  .buttons {
    display: flex;
    gap: 10px;
    margin-top: 15px;
  }

  .centered {
    justify-content: center;
  }
}

.custom__radio {
  display: flex;
  align-items: center;
}

.custom__radio-inline {
  display: inline;
}

.custom__radio .form-check {
  margin-left: 10px;
  margin-top: 10px;
}

.btn-switch .form-check-label {
  font-family: 'Dubai-medium';
}

.form-switch .form-check-input:focus {
  box-shadow: none;
  outline: none;
}

.itUpdate-label {
  margin-left: 40px;
  font-family: 'Dubai-medium';
  color: #161616;
  min-width: 300px;
}

.idcard_generate_modal {
  #RadioButtons {
    .buttons {
      justify-content: center;
    }
  }
}

.footer-btn{
  min-height: 150px;
}

