#Modal_backdrop {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: .5);
    right: 0;
    top: 0;
    z-index: 10000;

    &.display {
        display: flex;
        align-items: center;
        animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

        #Modal_canvas {
            opacity: 0;
            animation: scaleUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
    }

    &.display-none {
        animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

        #Modal_canvas {
            animation: scaleDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
    }

    &.hide {
        display: none;
    }

    #Modal_canvas {
        min-height: 200px;
        min-width: 400px;
        width: fit-content;
        background-color: white;
        border-radius: 10px;
        position: relative;
        margin: 0 auto;

        img.close_btn {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
        }
    }

    @keyframes fadeIn {
        0% {
            background: rgba(0, 0, 0, .0);
        }

        100% {
            background: rgba(0, 0, 0, .7);
        }
    }

    @keyframes fadeOut {
        0% {
            background: rgba(0, 0, 0, .7);
        }

        100% {
            background: rgba(0, 0, 0, .0);
        }
    }

    @keyframes scaleUp {
        0% {
            transform: scale(.8) translateY(1000px);
            opacity: 0;
        }

        100% {
            transform: scale(1) translateY(0px);
            opacity: 1;
        }
    }

    @keyframes scaleDown {
        0% {
            transform: scale(1) translateY(0px);
            opacity: 1;
        }

        100% {
            transform: scale(.8) translateY(1000px);
            opacity: 0;
        }
    }
}

body:has(#Modal_backdrop.display) {
    overflow: hidden;
}