#NavBarHeader {
    width: var(--navBarWidth);

    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;

        img {
            width: 150px;
        }
    }

    .options {
        display: flex;
        justify-content: center;
        gap: 20px;
        
        span {
            border: 1px solid white;
            padding: 2px 15px;
            border-radius: 5px;
            font-size: .8rem;
            cursor: pointer;
            user-select: none;
        }
    }
}