#AttachmentsEyeView {
    display: inline-flex;
    align-items: center;

    p {
        margin: 0;
        margin-right: 10px;
    }

    img.eye_icon {
        cursor: pointer;
        width: 28px;
    }
}