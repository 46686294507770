#RadioButtons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .wrapper {
        display: inline-flex;
        flex-direction: column;
        width: 100%;

        &>label {
            color: #161616;
            padding-bottom: 5px;

            em {
                color: red;
                margin: 0 5px;
                font-style: normal;
            }
        }

        .buttons {
            display: inline-flex;
            gap: 10px;
            height: 41px;
            align-items: center;

            .radiobtn {
                display: inline-flex;
                flex-direction: row-reverse;
                gap: 10px;
                accent-color: #B98772;
            }
        }
    }

    small {
        color: red;
    }

    &.css-rdb-esc2 {
        align-items: flex-start;

        .wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;

            &>label {
                padding: 0;
                width: 300px;
                line-height: 16px;
            }

            .buttons {
                gap: 15px;
                height: auto;

                .radiobtn {
                    gap: 5px;

                    &>label {
                        line-height: 16px;
                    }
                }
            }
        }
    }

    &.css-rdb-esc3 {
        align-items: center;
    }
}