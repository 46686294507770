span#Actions {
    display: inline-flex;
    gap: 5px;
    margin-left: 10px;

    img {
        cursor: pointer;
        padding: 3px;
        width: 20px;
        height: 20px;
        border-radius: 5px;
        box-sizing: content-box;
        object-fit: contain;
    }
}