#AttachmentContent {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    gap: 10px;
    width: 100%;

    span {
        position: relative;
        background-color: #e2e2e2;
        padding: 7px 5px 6px;
        border-radius: 5px;
        display: inline-flex;

        a {
            display: inline-flex;
        }

        &.image img.file {
            border-radius: 5px;
            object-fit: cover;
        }

        img.file {
            height: 40px;
            width: 40px;
        }

        p {
            position: absolute;
            text-align: center;
            bottom: -30px;
            right: 0;
            width: 100%;
        }

        img.close {
            position: absolute;
            top: -8px;
            right: -8px;
            cursor: pointer;
        }
    }
}