#SelectEmployee {
    div.inputs {
        margin-top: 15px;
        display: flex;
        gap: 10px;
        align-items: center;

        #Select {
            flex-basis: 20%;
        }
    }
}