span#EntriesCounter {
    display: inline-flex;
    align-items: center;
    gap: 15px;
    user-select: none;

    p {
        margin: 0;
        text-transform: capitalize;
        color: #1a1a1a;
        font-size: 17px;
        opacity: 54%;
    }

    span {
        position: relative;
        display: inline-flex;
        align-items: center;

        input {
            height: 35px;
            border: 1px solid hsla(18, 34%, 59%, .431);
            border-radius: 5px;
            direction: ltr;
            padding: 5px 10px;
            padding-left: 25px;
            width: 65px;
            text-align: center;

            &:focus {
                outline: none;
                // border-width: 3px;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }

            -moz-appearance: textfield;
        }

        i {
            position: absolute;
            left: 3px;
            display: inline-flex;
            flex-direction: column;

            img {
                cursor: pointer;
                scale: .8;
                padding: 3px;

                &:first-of-type {
                    transform: rotate(180deg);
                }

                &:hover {
                    scale: 1.1;
                }

                &:active {
                    scale: .6;
                }
            }
        }
    }
}