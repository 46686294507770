#Header {
  height: 90px;
  background-color: var(--color1);
  position: sticky;
  top: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 30px 30px 100px;
  z-index: 999;
  box-sizing: content-box;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0;

  .close {
    position: sticky;
    bottom: 0px;
    top: 0;
    left: 0;
    padding: 15px 20px;
    width: 100%;
    background: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    p {
      margin: 0;
    }
  }
  .content{
    padding-left: 20px;
    padding-right: 20px;
  }

  i.hamburger {
    cursor: pointer;

    img {
      width: 25px;
    }
  }

  div.user {
    display: flex;
    align-items: center;
    gap: 20px;

    &>.notification {
      position: relative;

      .notification__lists {
        position: absolute;
        left: -36px;
        min-width: 350px;
        max-height: 320px;
        overflow-y: auto;
        overflow-x: hidden;
        background: #fff;
        top: 35px;
        // padding: 30px;
        // padding-left: 15px;
        border-radius: 8px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 0;

        &::-webkit-scrollbar {
          width: 8px;
          border-radius: 100px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgb(255, 255, 255);
          background-color: #eee;
          border-radius: 100px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #3333;
          outline: 1px solid #fff;
          border-radius: 100px;
        }

        ul {
          padding: 0;
          margin: 0;

          li {
            padding: 10px 0;
            display: flex;
            align-items: self-start;
            justify-content: space-between;
            border-bottom: 1px solid #e3e3e3;

            span {
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              margin: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: pointer;
            }

            .spinner-border {
              width: 17px;
              height: 15px;
              margin-top: 10px;
            }

            img {
              margin-top: 5px;
              height: 20px;
              height: 25px;
              padding: 0;
              border-radius: 100px;
              width: 25px;
              box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
                rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
              cursor: pointer;
              margin-right: 5px;
              margin-left: 3px;
            }

            &:last-child {
              border: none;
              padding-top: 0;
            }

            &:first-child {
              padding-top: 0;
            }
          }
        }
      }

      .clear {
        position: sticky;
        bottom: 0px;
        left: 0;
        padding: 17px 8px;
        width: 100%;
        text-align: center;
        background: #fff;
        border-top: 1px solid #e3e3e3;
        cursor: pointer;

        p {
          color: var(--color2);
          margin: 0;
        }
      }

      span.count {
        position: absolute;
        display: block;
        width: 8px;
        height: 8px;
        background-color: #f32525;
        border-radius: 50%;
        left: -1px;
        top: 5px;
        text-align: center;
        color: white;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    i {
      img {
        width: 20px;
        cursor: pointer;
      }
    }

    p {
      line-height: 17px;

      &.name {
        font-weight: 600;
        color: var(--color16);
      }

      &.designation {
        color: var(--color2);
      }
    }

    .logout {
      margin-right: 50px;
    }
  }
}