span#TableCommentsViewer {
    display: inline-block;
    position: relative;
    padding-left: 30px;

    &.less__content {
        text-overflow: ellipsis;
        display: -webkit-box;
        // -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    img.read_more {
        height: 7px;
        position: absolute;
        left: 10px;
        top: 9px;
        cursor: pointer;

        &.hide {
            display: none;
        }

        &.up {
            rotate: 180deg;
        }
    }
}