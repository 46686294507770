div#AdvancedSearch {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-basis: 100%;

    img.toggle_arrow {
        position: relative;
        left: 0;
        top: 0;
    }

    #Select {
        align-self: flex-start;
    }

    article.items {
        display: flex;
        align-items: center;
        min-height: 41px;
        gap: 20px;
        flex-wrap: wrap;

        span {
            display: inline-flex;
            align-items: center;
            gap: 15px;
            height: 25px;
            background-color: #F6CDB9;
            padding: 0 8px;
            border-radius: 5px;

            p {
                margin: 0;
            }

            img {
                height: 15px;
                cursor: pointer;
            }
        }
    }
}