.show-document {
    display: flex;
    align-items: center;
    margin-top: 8px;

    p {
        margin: 0;
        font-family: "Dubai-medium";
        font-size: 14px;
    }

    img {
        height: 20px;
        margin-left: 10px;
    }
}

.details__page {
    margin-top: 30px;

    .details__inner {
        padding: 20px;

        img.avatar {
            height: 42px;
            width: 42px;
            margin-left: 10px;
            border-radius: 100px;
            margin-bottom: 20px;
            object-fit: cover;
        }

        span#TitleValue {
            margin-bottom: 20px;
        }

        .generate {
            align-items: center;
            justify-content: center;
            display: flex;
        }
    }

    .upload__image {
        width: 70%;
        margin: 0 auto;
        padding-top: 10px;
    }
}

.idcard_generate_modal {
    .idcardpreviewdiv {
        display: flex;
        justify-content: center;

        object.idpreviewStyle {
            width: 72% !important;
        }
    }
}