#ModalContent {
    min-width: 600px;

    p.header {
        background-color: #F4ECE8;
        padding: 10px;
        padding-right: 40px;
        font-size: 18px;
        font-family: 'Dubai-medium';
        border-radius: 10px 10px 0 0;
    }

    .modal_inner_content {
        padding: 40px;
        padding-top: 10px;

        h3 {
            font-size: 20px;
        }

        object {
            width: 100%;
            height: 400px;
            border-bottom: 3px solid;
            border-top: 3px solid;
            overflow: hidden;
        }
    }

    .modal_buttons {
        display: flex;
        justify-content: flex-end;
        gap: 15px;
        margin-top: 20px;

        &:empty {
            display: none;
        }
    }
}