div#Pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  margin-top: 10px;

  ul {
    display: flex;
    justify-content: end;
    list-style: none;
    margin: 0;

    li.page-item {

      a.page-link {
        cursor: pointer;
        padding: 5px 10px;
        margin: 0 5px;
        user-select: none;

      }

      &.disabled {
        a {
          background-color: inherit;
          display: none;
        }
      }
    }

    li.pageActive {
      a.page-link {
        border-radius: 4px;
        background: #b98772;
        color: #FFF;
      }
    }

    li:first-of-type {
      a {
        background-color: #eeeeee;
        border-radius: 5px;
      }
    }

    li:last-of-type {
      a {
        background-color: #eeeeee;
        border-radius: 5px;
      }
    }
  }

  p.status {
    direction: ltr;
    // color: #0d7b58;
  }
}