#Checkbox {
    display: inline-flex;
    flex-direction: column;

    &>span {
        display: inline-flex;
        gap: 10px;
        accent-color: #B98772;
        align-items: center;

        &.reverse {
            flex-direction: row-reverse;
        }

        label {
            em {
                color: red;
                margin: 0 5px;
                font-style: normal;
            }
        }
    }

    small {
        color: red;
        line-height: .875em;
    }

    .form-check-input {
        margin-top: 0;
    }

    &.css-cbx-esc2 {

        &>span {
            align-items: flex-start;
        }

        label {
            line-height: 20px;
        }

        input {
            height: 20px;
            aspect-ratio: 1 / 1;
            margin-top: 4px;
        }
    }
}