#RequestDetailsData {
    article {
        padding: 20px 15px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-auto-rows: minmax(50px, auto);
        gap: 10px;

        #TitleValue {
            &:nth-last-of-type(1) {
                grid-row-start: 2;
            }
        }
    }
}