#Layout {
    display: flex;
    
    main.main {
        transition: margin-right .5s ease-in;
        
        &.show {
            width: calc(100% - var(--navBarWidth));
            margin-right: var(--navBarWidth);
        }
        
        &.hide {
            margin-right: 0;
            width: 100%;
        }

        div.outlet>div {
            padding: 30px;
            max-width: 1500px;
        }
    }

    aside.navigation {
        position: fixed;
        right: 0;
        height: 100vh;
        background-color: var(--color5);
        overflow: hidden;
        transition: width .5s ease-in;
        color: white;

        section {
            width: var(--navBarWidth);
        }

        &.show {
            width: var(--navBarWidth);
        }

        &.hide {
            width: 0;
        }
    }
}