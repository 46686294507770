span#FileUpload {
    display: inline-block;

    p.label {
        em {
            color: red;
            margin: 0 5px;
            font-style: normal;
        }
    }

    label {
        background-color: #F4ECE8;
        display: inline-flex;
        gap: 15px;
        padding: 8px 20px;
        border-radius: 5px;
        color: #474747;
        font-weight: bold;
        cursor: pointer;

        img {
            width: 18px;
        }

        &.reverse {
            flex-direction: row-reverse;
        }
    }

    small.error {
        display: block;
        color: red;
    }

    .attachment-info {
        font-size: 14px;
        display: flex;
        align-items: center;
        margin: 0;
        margin-top: 7px;

        img {
            height: 15px;
            margin-left: 5px;
        }
    }

    #AttachmentContent {
        margin-top: 20px;
    }

    &.css-atc-esc1 {
        p.label {
            display: none;
        }

        .file_info {
            display: none;
        }
    }

    &.css-atc-esc2 {
        width: 100%;
        margin-bottom: 15px;

        &>span {
            display: inline-block;
            width: 100%;
        }

        p.label {
            background-color: inherit;
            color: #161616;
            margin: 0;
            padding: 0;
            padding-bottom: 5px;
            font-weight: normal;
        }

        label {
            background-color: #EFF2F7;
            border-radius: 0 5px 5px 0;
            font-weight: normal;
            width: 150px;
        }

        .file_info {
            border: 1px solid #d9d9d99e;
            padding: 7px 20px;
            border-radius: 5px 0 0 5px;
            display: inline-block;
            width: calc(100% - 150px);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            float: left;
            cursor: default;
            pointer-events: none;

            &.file {
                cursor: pointer;
                pointer-events: auto;
            }
        }

        .upload_icon {
            display: none;
        }
    }

    &.css-atc-esc3 {
        .file_info {
            display: none;
        }

        label {
            background-color: inherit;
            border: 1px solid #0c124a;
            font-weight: normal;
            padding: 6px 20px;
        }
    }
}