#ReportCounts {
    display: flex;
    justify-content: space-around;
    padding: 40px 70px;
    gap: 20px;
    flex-wrap: wrap;

    span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 2px solid;
        border-radius: 15px;
        width: 110px;
        height: 70px;

        p {
            line-height: 25px;
            margin: 0;

            &:first-child {
                font-size: 18px;
            }

            &:last-child {
                font-size: 28px;
                font-weight: 600;
            }
        }



        &.Open {
            color: #66AA94;
            border-color: #66AA94;
        }

        &.Processing {
            color: #EBB970;
            border-color: #EBB970;
        }

        &.Need {
            color: #B98772;
            border-color: #B98772;
        }

        &.Forwarded {
            color: #7782E2;
            border-color: #7782E2;
        }

        &.Rejected {
            color: #F56F6F;
            border-color: #F56F6F;
        }

        &.Closed {
            color: #0A0F31;
            border-color: #0A0F31;
        }

        &.Approved {
            color: #549B54;
            border-color: #549B54;
        }
    }
}