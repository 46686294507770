#TitleBar {
    background-color: #F4ECE8;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-radius: 8px 8px 0 0;

    h3 {
        color: #373737;
        font-size: 19px;
        line-height: 19px;
        margin: 0;
        font-family: 'Dubai-medium';
    }

    img {
        color: #161616;
        cursor: pointer;
        rotate: 180deg;

        &.tb__collapse {
            rotate: 0deg;
        }
    }

    &~section.expandable__section {
        border: 1px solid #d9d9d9;
        border-top: none;
        border-radius: 0 0 8px 8px;
        padding:  20px;
        // overflow: hidden;

        &.tb__hide {
            border: none;
            overflow: hidden;
            height: 0;
            padding: 0;
        }
    }
    &.css-tbr-esc2 {
        background-color: inherit;
        border-bottom: 2px solid #F4ECE8;
        margin-bottom: 25px;
        padding-right: 0;
    }
}