#AttachmentsPreview {
    position: relative;
    min-width: 600px;
    padding: 25px;

    button.download {
        position: absolute;
        left: 10px;
        top: 10px;
        background-color: #00000079;
        color: white;
        cursor: pointer;
        border: none;
        border-radius: 5px;

        &:focus {
            outline: none;
        }
    }

    #ViewAttachmentModal {
        height: 300px;
        position: relative;
        display: flex;
        justify-content: center;

        img.preview {
            max-width: 100%;
            cursor: pointer;
        }

        object.preview {
            width: calc(100% + 80px);
            height: 300px;
        }

        video {
            // width: 100%;
            // height: 100%;
            border-radius: 10px;
        }

        section.slider {
            position: absolute;
            bottom: -170px;
            display: flex;
            gap: 30px;
            align-items: center;
            user-select: none;

            i.arrow {
                height: fit-content;
                cursor: pointer;
                width: 10px;

                &.left {
                    rotate: 90deg;
                }

                &.right {
                    rotate: -90deg;
                }
            }

            div.files {
                display: flex;
                gap: 15px;

                span.preview {
                    img {
                        box-sizing: border-box;
                        border: 2px solid black;
                        padding: 3px;
                    }

                    P {
                        width: 9ch;
                    }
                }

                span {
                    cursor: pointer;
                }

                img {
                    background-color: white;
                    width: 60px;
                    height: 60px;
                    object-fit: cover;
                    border-radius: 7px;
                    padding: 3px;
                }

                p {
                    width: 8ch;
                    height: 3ch;
                    font-size: .8rem;
                    color: white;
                    overflow: hidden;
                    text-align: center;
                    direction: ltr;
                    margin: 0;
                    font-weight: 500;
                }
            }
        }
    }
}

body div.tooltip.show {
    z-index: 10000;
}