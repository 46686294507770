span#Input {
    display: inline-flex;
    flex-direction: column;

    $icon_width: 18px;
    $icon_margin: 8px;
    $input_x_padding: 15px;

    label {
        em {
            color: red;
            margin: 0 5px;
            font-style: normal;
        }
    }

    span {
        position: relative;
        display: inline-flex;
        align-items: center;

        &.has-fixed-value {
            .fixed-value {
                display: inline-flex;
                height: 42px;
                background-color: #EFF2F7;
                border-radius: 0 5px 5px 0;
                padding: 0 20px;
                width: fit-content;
            }

            input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        input {
            box-sizing: border-box;
            border: none;
            background-color: #f3f3f3;
            border-radius: 5px;
            height: 30px;
            padding-right: $input_x_padding;
            padding-left: calc($icon_width + ($icon_margin * 2));
            width: 100%;

            &:focus {
                outline: none;

            }

            &[type='checkbox'] {
                width: 20px;
                height: 20px;
                appearance: auto;
            }
        }

        img {
            position: absolute;
            width: $icon_width;
            left: $icon_margin;
        }

        span.close_btn {
            position: absolute;
            left: 8px;
            cursor: pointer;
            user-select: none;
        }
    }

    small.error {
        color: red;
    }

    &.reverse {
        span {
            input {
                padding-right: calc($icon_width + ($icon_margin * 2));
                padding-left: $input_x_padding;
            }

            img {
                right: $icon_margin;
            }
        }
    }

    &.text.css-inp-esc1,
    &.css-inp-esc1 {
        width: 100%;
        margin-bottom: 15px;

        label {
            color: #161616;
            margin: 0;
            padding-bottom: 5px;
        }

        input {
            padding: 20px 15px;
            color: #212529;
            background-color: #fff;
            border: 1px solid hsla(0, 0%, 85%, 62%);
            border-radius: 0.25rem;

            &:focus {
                border: 1px solid hsla(0, 0%, 85%, 62%);
            }

            &:disabled {
                border: 1px solid hsla(0, 0%, 85%, 42%);
                color: #999999;
                background-color: #f3f3f3;

                &::placeholder {
                    color: #999999;
                }
            }
        }

        span.close_btn {
            color: #B98772;
        }

    }

    &.text.css-inp-esc2,
    &.css-inp-esc2 {
        input {
            background-color: white;
            border: 1px solid hsla(18, 34%, 59%, .431) !important;
            direction: rtl;
            min-width: 312px;
            padding: 6px 41px 6px 20px;
            min-height: 35px;

            &:focus {
                border-width: 2px;
            }
        }

        span.close_btn {
            color: #B98772;
        }
    }
}