span#DatePick {
    display: inline-flex;
    flex-direction: column;

    label {
        em {
            color: red;
            margin: 0 5px;
            font-style: normal;
        }
    }

    span {
        display: inline-flex;
        align-items: center;
        position: relative;

        img {
            position: absolute;
            left: 10px;
        }

        span.close_btn {
            position: absolute;
            left: 10px;
            cursor: pointer;
        }

        input {
            width: 100%;
            height: 41px;
            box-sizing: border-box;
            padding: 0;
            padding-right: 15px;
            padding-left: 35px;
            font-size: 1rem;
        }
    }

    small.error {
        color: red;
    }

    &.css-dtp-esc1 {

        label {
            font-weight: bold;
            margin-bottom: 5px;
        }

        input {
            border-radius: 0;
            border: 1px solid hsla(18, 34%, 59%, .941);
            border-radius: 6px;
            min-width: 100px;
            color: #333333;

            &:focus {
                outline: none;
                border: 2px solid #9e9e9e;
            }

            &::placeholder {
                color: #808080;
                ;
            }
        }
    }

    &.css-dtp-esc2 {
        width: 100%;

        .react-datepicker-ignore-onclickoutside {
            border: 1px solid #ced4da;
            box-shadow: none;
            outline: none;
        }

        input {
            border: 1px solid #ced4da;
            appearance: none;
            border-radius: 0.375rem;
        }
    }

    &.css-dtp-esc3 {

        label {
            font-weight: bold;
        }

        .react-datepicker-ignore-onclickoutside {
            border: 1px solid hsla(18, 34%, 59%, .941) !important;
            border-radius: 6px !important;
            box-shadow: none;
            outline: none;
        }

        input {
            appearance: none;
            border: 1px solid hsla(18, 34%, 59%, .941) !important;
            border-radius: 6px !important;
            width: 130px;
        }
    }
}