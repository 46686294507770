span#TitleValue {
    display: inline-flex;
    flex-direction: column;
    gap: 10px;

    p {
        margin: 0;
        display: inline-block;
        line-height: 18px;
        color: #161616;

        &.key {
            color: #161616;
            font-family: 'Dubai-medium';
        }

        &.value {
            white-space: pre-line;
            justify-content: flex-start;
            font-size: 15px;
            display: inline-flex;
            gap: 8px;

            img {
                height: 20px;
            }
        }
    }

    small.error {
        color: red;
    }

    &.css-tvl-esc2 {
        p {
            padding-bottom: 5px;
            font-family: 'Dubai-regular';
            font-weight: 400;
        }

        .inputs {
            padding: 7px 0;
        }
    }
}