ul.pagination {
    justify-content: end;
    padding-top: 15px;
  }
  
  .page-item:first-child .page-link {
    border: none;
    color: var(--gold);
  }
  
  li.page-item .page-link {
    border: none;
    color: var(--blue);
    font-size: 14px;
    padding: 5px 10px;
    margin: 0 10px;
  }
  
  li.page-item .page-link:focus {
    box-shadow: none;
    outline: none;
  }
  
  li.page-item.pageActive .page-link {
    border-radius: 4px;
    background: #b98772;
    color: #FFF !important;
  }