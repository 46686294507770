div#TextArea {
    display: flex;
    flex-direction: column;

    textarea {
        border: none;
        border-radius: 7px;
        height: 150px;
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        resize: none;

        &:focus {
            outline: none;
        }
    }

    small.error {
        color: rgb(227, 0, 0);
    }

    &:not(.css-txt-esc1, .css-txt-esc2, .css-txt-esc3) {

        textarea {
            border: 1px solid black;
            background-color: gray;
            border-radius: 0;
        }

        &:focus {
            outline: 2px solid black;
        }
    }

    &.css-txt-esc1 {
        label {
            color: #161616;
            margin: 0;
            padding-bottom: 5px;
        }

        textarea {
            background-color: inherit;
            border: 1px solid #d9d9d99e;

            &:focus {
                outline: none;
            }
        }
    }

    &.css-txt-esc2 {
        label {
            background-color: #F4ECE8;
            color: #373737;
            font-size: 19px;
            line-height: 19px;
            padding: 10px 20px;
            border-radius: 8px 8px 0 0;
            font-family: "Dubai-medium";
        }

        textarea {
            background-color: inherit;
            border: 1px solid #d9d9d9;
            border-top: none;
            border-radius: 0 0 8px 8px;
        }
    }

    &.css-txt-esc3 {
        textarea {
            background-color: #f3f3f3;
        }
    }
}