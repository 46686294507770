#SearchBox {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 15px;
    background-color: #F4ECE8;
    padding: 20px 25px;
    border-radius: 15px;
    position: relative;

    .inputs {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 15px;
    }

    .search_button {
        display: flex;
        gap: 6px;
    }

    .export_btn {
        position: absolute;
        top: 15px;
        left: 20px;
    }
}