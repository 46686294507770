span#Select {
    display: inline-flex;
    flex-direction: column;

    span.select__indicator-separator,
    span.css-1u9des2-indicatorSeparator,
    span.css-894a34-indicatorSeparator {
        display: none;
    }

    &:not(.css-slc-esc1, .css-slc-esc2, .css-slc-esc3, .css-slc-esc4) {

        // active box
        // inactive box
        .css-t3ipsp-control,
        .css-13cymwt-control {
            background-color: gainsboro;
        }
    }

    p.title {
        em {
            color: red;
            margin: 0 5px;
            font-style: normal;
        }

        img.toggle_arrow {
            margin-right: 10px;
            position: relative;
            top: -3px;
            cursor: pointer;
            user-select: none;

            &.collapsed {
                rotate: 180deg;
            }
        }
    }

    // inactive option
    // hover option
    // selected option
    .css-10wo9uf-option,
    .css-d7l1ni-option,
    .css-tr4s17-option {
        padding-top: 0;
        padding-bottom: 0px;
    }

    // selected option
    .css-tr4s17-option {
        background-color: #f4ece8;
        color: #373737;
    }

    .css-1n6sfyn-MenuList::-webkit-scrollbar {
        width: 8px;
        border-radius: 100px;
    }

    .css-1n6sfyn-MenuList::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgb(255, 255, 255);
    }

    .css-1n6sfyn-MenuList::-webkit-scrollbar-thumb {
        background-color: #3333;
        outline: 1px solid #fff;
        border-radius: 100px;
    }

    .css-13cymwt-control {
        transition: none;
    }


    &.close_button_appear {
        .select_span {
            position: relative;
            display: inline-flex;
            align-items: center;

            .css-13cymwt-control,
            .css-t3ipsp-control {
                padding-left: 30px;
            }

            .css-1xc3v61-indicatorContainer,
            .css-15lsz6c-indicatorContainer {
                display: none;
            }

            .css-b62m3t-container,
            .css-3iigni-container {
                flex-grow: 1;
            }

            .close_btn {
                position: absolute;
                left: 10px;
                cursor: pointer;
            }
        }
    }

    small.error {
        color: red;
    }

    &.css-slc-esc1 {
        width: 100%;
        margin-bottom: 15px;

        p.title {
            color: #161616;
            margin: 0;
            padding-bottom: 5px;
        }

        // active box
        .css-13cymwt-control,
        .css-t3ipsp-control {
            border-color: hsla(0, 0%, 85%, 62%);
            box-shadow: none;
        }

        // hover option
        .css-d7l1ni-option {
            background-color: #9e9e9e17;
            color: #B98772fa;
        }
    }

    &.css-slc-esc2 {

        p {
            font-weight: bold;
            margin-bottom: 5px;
        }

        // active box
        .css-t3ipsp-control {
            border: 1px solid hsla(18, 34%, 59%, .941);
            box-shadow: none;
        }

        // inactive box
        .css-13cymwt-control {
            background-color: #fff;
            border: 1px solid hsla(18, 34%, 59%, .941);
            border-radius: 6px;
            padding: 0;
        }

        // hover option
        .css-d7l1ni-option {
            background-color: #9e9e9e17;
            color: #B98772fa;
        }
    }

    &.css-slc-esc3 {
        p {
            font-weight: bold;
            margin-bottom: 5px;
        }

        // inactive box
        .css-13cymwt-control {
            border: 1px solid #0A0F31;
            width: 130px;
            height: 40px;
            box-sizing: border-box;
        }

        // active box
        .css-t3ipsp-control {
            border: 1px solid #0A0F31;
            box-shadow: none;
            width: 130px;
            height: 40px;
        }

        // hover option
        .css-d7l1ni-option {
            background-color: #9e9e9e17;
            color: #B98772fa;
        }

        // placholder text
        .css-1jqq78o-placeholder {
            color: #0A0F31;
            text-align: center;
        }

        // disabled box
        .css-16xfy0z-control {
            border: 1px solid #0A0F3141;
            width: 130px;
            background-color: rgba(0, 0, 0, 0.05);

            // placeholder
            .css-1jqq78o-placeholder {
                color: rgba(0, 0, 0, 0.5);
            }
        }
    }
}