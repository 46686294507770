#NavItemsListing {
    margin-top: 30px;

    ul {
        padding-inline-start: 20px;
        padding-left: 0;

        li {
            cursor: pointer;
            box-sizing: content-box;
        }
    }

    li.item {
        margin: 10px 0;

        i {
            //general
            margin-left: 10px;
        }

        &>span {
            width: 85%;
            border-radius: 5px;
            padding: 5px 7px;
            display: flex;
            align-items: center;

            a {
                display: flex;
                align-items: center;
                color: white;
                text-decoration: none;
            }

            &.selected {
                background-color: var(--color2);
            }


            i {
                img {
                    width: 23px;
                    height: 20px;
                }
            }

            p {
                font-size: 18px;
                margin-bottom: 0;
            }

        }

        ul {
            padding-right: 35px;
            overflow: hidden;

            &.hide {
                height: 0;
                display: none;
            }

            li.sub-item {
                opacity: .8;
                margin: 5px 0;

                a {
                    display: flex;
                    align-items: center;
                    padding: 2px 5px;

                    i {
                        width: 17px;
                    }

                    p {
                        width: fit-content;
                        display: inline;
                        position: relative;
                        font-size: 14px;
                        margin: 0;

                        &.selected:before {
                            content: "";
                            position: absolute;
                            width: 100%;
                            bottom: -5px;
                            border-bottom: 3px solid var(--color2);
                        }
                    }
                }
            }
        }
    }
}