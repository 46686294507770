span#StatusBox {
    p {
        margin: 0;
        text-align: center;
    }

    span.box {
        display: inline-block;
        text-align: center;
        color: white;
        border-radius: 5px;
        width: 115px;
        padding: 2px 2px;
        font-size: 14px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.approved {
            background-color: #549B54;
        }

        &.closed {
            background-color: #0A0F31;
        }

        &.draft {
            background-color: #d08f2e;
        }

        &.forwarded {
            background-color: #7782E2;
        }

        &.needinfo {
            background-color: #B98772;
        }

        &.open {
            background-color: #4f9274;
        }

        &.pending {
            background-color: #F56F6F;
        }

        &.processing {
            background-color: #EBB970;
        }

        &.rejected {
            background-color: #f56f6fff;
        }

        &.reopened {
            background-color: #7c7b3b;
        }

        &.returned {
            background-color: #B98772;
        }

        &.subtaskassigned {
            background-color: #40619b;
        }

        &.subtaskclosed {
            background-color: #4c3b86;
        }

        &.subtaskopen {
            background-color: #1c7b86;
        }

        &.subtaskreopened {
            background-color: #ac7b86;
        }

        &.taskassigned {
            background-color: #4c6b86;
        }

        &.taskclosed {
            background-color: #1c4b86;
        }

        &.taskopen {
            background-color: #3c8b86;
        }

        &.taskreopened {
            background-color: #acab86;
        }
    }
}